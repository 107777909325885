<template>
    <div id="parent-entity-page-title">
        <span id="parent-entity-title">{{ this.title }}</span>
        <div v-if="identifier">
            <span id="parent-entity-title-divider"></span> <span id="parent-entity-identifier"> {{ this.identifier }}</span>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    identifier: {
      type: String
    },
    title: {
      type: String
    }
  }
}
</script>
