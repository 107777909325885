<template>
  <div>
    <confirmation ref="Confirmation"></confirmation>
    <contactModal ref="ContactModal"></contactModal>
    <datatable-heading
      :hasTitleSection="false"
      :changePageSize="changePageSize"
      :searchChange="_.debounce(searchChange, 500)"
      :from="from"
      :to="to"
      :total="total"
      :perPage="perPage"
      :titleAndButton="true"
    >
      <template v-slot:button>
        <div class="title-and-right-element">
          <PageTitle :title="'Contact'" :identifier="'Company #'+ companyID"></PageTitle>
          <div>
            <b-button
              v-b-modal.modalright
              variant="primary"
              size="lg"
              class="top-right-button mr-2"
              @click="addContact()"
              >Add New</b-button
            >
            <b-button
              v-b-modal.modalright
              variant="primary"
              size="md"
              style="font-size: 18px"
              class="simple-icon-trash"
              v-show="isTrashVisible"
              @click="deleteItems()"
            ></b-button>
          </div>
        </div>
      </template>
    </datatable-heading>

    <b-overlay :show="gridLoading" variant="transparent" blur="5px" opacity="1">
      <b-row>
        <b-colxx xxs="12">
          <b-card>
            <vuetable
              ref="vuetable"
              track-by="contactID"
              :api-url="apiBase"
              :query-params="makeQueryParams"
              :per-page="perPage"
              :reactive-api-url="false"
              :fields="fields"
              :css="css.table"
              pagination-path
              :row-class="onRowClass"
              @vuetable:pagination-data="onPaginationData"
              @vuetable:row-clicked="rowClicked"
              @vuetable:cell-rightclicked="rightClicked"
              @vuetable:load-error="handleLoadError"
              @vuetable:cell-clicked="cellclicked"
              @vuetable:loading="onVuetableLoading"
              @vuetable:loaded="onVuetableLoaded"
            >
            <template slot="phoneNumbers" slot-scope="props">
              <div v-if="props.rowData.phoneNumbers" @contextmenu="rightClicked(props.rowData, '', $event)">
                <div v-for="number in props.rowData.phoneNumbers.split(',')" :key="number">
                  {{number | VMask(phoneMask)}}
                </div>
              </div>
            </template>
              <template slot="notes" slot-scope="props">
                 <td class="text-center">
                <div @contextmenu="rightClicked(props.rowData, '', $event)">
                  <i
                    v-if="props.rowData.notes != null"
                    class="simple-icon-note"
                    style="font-size: 1.7em"
                    :id="props.rowData.notes"
                  />
                  <b-tooltip
                    v-if="props.rowData.notes != null"
                    :target="props.rowData.notes"
                    placement="topleft"
                    :title="`${notesToolTip(props.rowData.notes)}`"
                  ></b-tooltip>
                </div>
                </td>
              </template>
              <template slot="contactTypeDescriptions" slot-scope="props">
                <div @contextmenu="rightClicked(props.rowData, '', $event)">
                  <ReadMoreList separator="," :linesToShow="3" :listText="props.rowData.contactTypeDescriptions" />
                </div>
              </template>
            </vuetable>
          </b-card>
          <vuetable-pagination-bootstrap
            class="mt-4"
            ref="pagination"
            @vuetable-pagination:change-page="onChangePage"
          />
        </b-colxx>
      </b-row>
    </b-overlay>

    <v-contextmenu ref="contextmenu">
      <v-contextmenu-item @click="onContextMenuAction('edit/view')">
        <i class="simple-icon-docs" />
        <span>Edit/View</span>
      </v-contextmenu-item>
      <v-contextmenu-item @click="onContextMenuAction('delete')">
        <i class="simple-icon-trash" />
        <span>Delete</span>
      </v-contextmenu-item>
    </v-contextmenu>
  </div>
</template>

<script>
import confirmation from '../../../components/Common/Confirmation.vue'
import Vuetable from 'vuetable-2/src/components/Vuetable'
import VuetablePaginationBootstrap from '../../../components/Common/VuetablePaginationBootstrap'
import { BeaconapiUrl } from '../../../constants/config'
import CompanyContactMixin from '../../../mixins/CompanyContactMixin.vue'
import DatatableHeading from '../../../containers/datatable/DatatableHeading'
import CssTableConfig from '../../../constants/VuetableBootstrapconfig'
import contactModal from '../../../components/Modals/CompanyContactModal.vue'
import ReadMoreList from '../../../components/Listing/ReadMoreList.vue'
import ParentEntityPageTitle from '../../../components/Common/ParentEntityPageTitle.vue'

// so we can set our messages in the layout if needed
import { mapMutations, mapActions } from 'vuex'

export default {
  props: {
    companyID: {
      type: Number,
      default: 0
    }
  },

  components: {
    vuetable: Vuetable,
    'vuetable-pagination-bootstrap': VuetablePaginationBootstrap,
    'datatable-heading': DatatableHeading,
    ContactModal: contactModal,
    Confirmation: confirmation,
    ReadMoreList: ReadMoreList,
    PageTitle: ParentEntityPageTitle

  },
  mixins: [CompanyContactMixin],
  data () {
    // because the id field is used in other places throughout this page here it should be
    // identified as a variable so that we don't have it hardcoded everywhere
    var _idfield = 'contactID'
    return {
      phoneMask: '(###) ###-####',
      isLoad: false,
      apiBase: `${BeaconapiUrl}/api/company-contact/table/${this.companyID}`,
      sort: '',
      page: 1,
      perPage: 10,
      search: '',
      from: 0,
      to: 0,
      total: 0,
      lastPage: 0,
      items: [],
      selectedItems: [],
      idfield: _idfield,
      css: CssTableConfig,
      showModal: false,
      modalText: '',
      gridLoading: false,
      fields: [
        {
          name: '__checkbox',
          title: '',
          titleClass: 'center aligned text-right',
          dataClass: 'center aligned text-right',
          width: '5%'
        },
        {
          name: _idfield,
          sortField: _idfield,
          title: 'ContactID',
          titleClass: '',
          dataClass: 'list-item-heading text-muted',
          width: '15%'
        },
        {
          name: 'firstName',
          sortField: 'firstName',
          title: 'First Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: 'lastName',
          sortField: 'lastName',
          title: 'Last Name',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: '__slot:contactTypeDescriptions',
          sortField: 'contactTypeDescriptions',
          title: 'Contact Type',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: '__slot:phoneNumbers',
          title: 'Phone Numbers',
          titleClass: '',
          dataClass: 'text-muted',
          width: '15%'
        },
        {
          name: 'emailAddress',
          sortField: 'emailAddress',
          title: 'Email Address',
          titleClass: 'display:flex;justify-content:center;align-items;center;',
          dataClass: 'text-muted',
          width: '25%'
        },
        {
          name: '__slot:notes',
          title: 'Notes',
          titleClass: 'display:flex;justify-content:center;align-items;center;',
          dataClass: 'text-muted',
          width: '20%'
        }
      ]
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage'
    ]),
    makeQueryParams (sortOrder, currentPage, perPage) {
      this.$refs.vuetable.selectedTo = []
      return sortOrder[0]
        ? {
          sort: sortOrder[0]
            ? sortOrder[0].field + '|' + sortOrder[0].direction
            : '',
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
        : {
          page: currentPage,
          per_page: this.perPage,
          search: this.search
        }
    },
    onVuetableLoading () {
      this.gridLoading = true
    },
    onVuetableLoaded () {
      this.gridLoading = false
    },
    onRowClass (dataItem, index) {},
    rowClicked (dataItem, event) {
      // check to see if we are clicking on the readMore/readLess link, if so do not select or unselect the row.
      if (!(event.path !== null && event.path[0] !== null && event.path[0].id !== null && (event.path[0].id.indexOf('readMore') === 0 || event.path[0].id.indexOf('readLess') === 0))) {
        const itemId = dataItem[this.$data.idfield]

        if (this.$refs.vuetable.selectedTo.includes(itemId)) {
          this.$refs.vuetable.selectedTo = this.$refs.vuetable.selectedTo.filter(
            (x) => x !== itemId
          )
        } else {
          this.$refs.vuetable.selectedTo.push(itemId)
        }
        this.selectedItems = this.$refs.vuetable.selectedTo
      }
    },
    rightClicked (dataItem, field, event) {
      event.preventDefault()
      this.rightClickedItem = dataItem
      const itemId = dataItem[this.$data.idfield]

      if (!this.$refs.vuetable.selectedTo.includes(itemId)) {
        this.$refs.vuetable.selectedTo = [itemId]
      }
      this.$refs.contextmenu.show({ top: event.pageY, left: event.pageX })
      this.selectedItems = this.$refs.vuetable.selectedTo
    },
    onPaginationData (paginationData) {
      this.from = paginationData.from
      this.to = paginationData.to
      this.total = paginationData.total
      this.lastPage = paginationData.last_page
      this.items = paginationData.data
      this.$refs.pagination.setPaginationData(paginationData)
    },
    onChangePage (page) {
      this.$refs.vuetable.changePage(page)
    },

    changePageSize (perPage) {
      this.perPage = perPage
      this.$refs.vuetable.refresh()
    },

    searchChange (val) {
      this.search = val
      this.$refs.vuetable.refresh()
    },
    getIndex (value, arr, prop) {
      for (var i = 0; i < arr.length; i++) {
        if (arr[i][prop] === value) {
          return i
        }
      }
      return -1
    },
    cellclicked (dataitem, field, event) {},
    onContextMenuAction (action) {
      var rightClickedItem = this.rightClickedItem
      switch (action) {
        case 'delete': {
          this.deleteItems(rightClickedItem.contactID)
          break
        }
        case 'inactive': {
          break
        }
        case 'edit/view': {
          if (this.$refs.vuetable.selectedTo.length > 1) {
            this.$refs.Confirmation.show({
              title: 'Error',
              message: 'You may only edit one item at a time',
              okButton: 'Cancel',
              exitFunction: (e) => {
                if (['headerclose', 'esc', 'backdrop'].includes(e.trigger)) {
                  this.$refs.vuetable.refresh()
                }
              }
            })
          } else {
            this.$refs.ContactModal.show({
              title: `Edit/View For Contact ID: ${this.$refs.vuetable.selectedTo[0]}`,
              contactID: this.$refs.vuetable.selectedTo[0],
              exitFunction: () => {
                history.replaceState({}, '', location.pathname)
                this.refreshVueTable()
              }
            })
            const urlParams = new URLSearchParams()
            urlParams.set('contactID', this.$refs.vuetable.selectedTo[0])
            history.replaceState(null, null, '?' + urlParams.toString())
          }
          break
        }
        default:
          break
      }
    },
    // delete one or multiple items
    async deleteItems (record) {
      let message = ''; let title = 'Delete Contact'
      if (record) {
        message = `Are you sure you want to delete ${record}?`
      } else {
        if (this.$refs.vuetable.selectedTo.length > 1) {
          title = 'Delete Contacts'
          message = 'Are you sure you want to delete these records?'
        } else {
          message = `Are you sure you want to delete ${this.$refs.vuetable.selectedTo[0]}?`
        }
      }
      this.$refs.Confirmation.show({
        title: title,
        message: message,
        okButton: 'Delete'
      }).then(async result => {
        if (result) {
          // loop through all selected items from the grid
          this.$refs.vuetable.selectedTo.forEach(async item => {
            this.setInfoMessage('')
            // dispatch vuex action to delete each selected service
            await this.deleteCompanyContact(item)
            if (this.statusCode === 200) {
              this.refreshVueTable()
            }
          })
        }
      })
    },
    handleLoadError (response) {
      this.setDangerMessage(response)
    },
    refreshVueTable () {
      this.$refs.vuetable.refresh()
    },
    addContact () {
      this.$refs.ContactModal.show({
        title: 'Add New Contact',
        companyID: this.companyID,
        exitFunction: () => {
          this.refreshVueTable()
        }
      })
    },
    notesToolTip (props) {
      if (props != null) {
        if (props.length >= 100) {
          return props.substring(0, 100) + '...'
        } else {
          return props
        }
      } else {
        return props
      }
    },
    contactSel (value) {
      return value
    }
  },
  mounted () {
    const urlParams = new URL(location.href).searchParams
    this.contactID = urlParams.get('contactID')
    if (this.contactID) {
      this.$refs.ContactModal.show({
        title: `Edit/View For Contact ID: ${this.contactID}`,
        contactID: this.contactID,
        exitFunction: () => {
          history.replaceState({}, '', location.pathname)
          this.refreshVueTable()
        }
      })
    }
  },
  computed: {
    isTrashVisible () {
      return this.selectedItems.length > 0
    }
  }
}
</script>
