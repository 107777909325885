<template>
<div>
    <p v-html="formattedString"></p>
    <span v-show="listCount > linesToShow">
        <a id="readMore" v-show="!showingMore" v-on:click="triggerReadMore($event, true)">{{moreStr}}</a>
        <a id="readLess" v-show="showingMore" v-on:click="triggerReadMore($event, false)">{{lessStr}}</a>
    </span>
</div>
</template>
<style scoped>
#readMore {
  font-weight: 600;
  color: #005175;
  cursor: pointer;
}
#readMore:hover {
  color: #74a2bf;
}
#readLess {
  font-weight: 600;
  color: #005175;
  cursor: pointer;

}
#readLess:hover {
  color: #74a2bf;
}
</style>
<script>
import integer from 'vuelidate/lib/validators/integer'
export default {
  props: {
    moreStr: {
      type: String,
      default: 'read more'
    },
    lessStr: {
      type: String,
      default: 'read less'
    },
    listText: {
      type: String,
      default: ''
    },
    separator: {
      type: String,
      required: true
    },
    linesToShow: {
      type: Number,
      default: 1
    },
    itemsInLine: {
      type: Number,
      default: 1
    }
  },

  data () {
    return {
      showingMore: false
    }
  },

  computed: {
    formattedString () {
      var textToDisplay = ''

      if (!this.showingMore && this.listCount > this.linesToShow) {
        for (let i = 0; i < this.linesToShow; i++) {
          textToDisplay = textToDisplay + this.listArray[i] + '<br/>'
        }
      } else if (this.itemsInLine > 1) {
        for (let i = 0; i < this.listCount; i++) {
          if (i === this.listCount - 1) {
            textToDisplay = textToDisplay + this.listArray[i]
          } else if ((i + 1) % this.itemsInLine === 0) {
            textToDisplay = textToDisplay + this.listArray[i] + ',<br/>'
          } else {
            textToDisplay = textToDisplay + this.listArray[i] + ', '
          }
        }
      } else {
        for (let i = 0; i < this.listCount; i++) {
          textToDisplay = textToDisplay + this.listArray[i] + '<br/>'
        }
      }

      return (textToDisplay)
    },
    listCount () {
      if (this.listText) {
        return this.listText.split(this.separator).length
      } else {
        return 0
      }
    },
    listArray () {
      if (this.listText) {
        return this.listText.split(this.separator)
      } else {
        return []
      }
    }
  },

  methods: {
    triggerReadMore (e, showMore) {
      this.showingMore = showMore
    }
  }
}
</script>
