<script>
import axios from 'axios'
import { BeaconapiUrl } from '../constants/config.js'
import { instance } from '../../src/request/app-axios.js'
import { mapMutations } from 'vuex'

export default {
  data () {
    return {
      statusCode: null,
      affiliate: {},
      affiliates: [],
      affiliateTypes: [],
      consultants: [],
      affiliatePhones: [],
      affiliatePhone: {},
      affiliateContact: {},
      contactTypeOptions: []
    }
  },
  methods: {
    ...mapMutations([
      'setDangerMessage',
      'setAlertMessage',
      'setInfoMessage',
      'appendInfoMessage',
      'appendDangerMessage'
    ]),
    async getAllAffiliateTypes () {
      await axios
        .get(`${BeaconapiUrl}/api/affiliate/type`)
        .then(result => {
          this.affiliateTypes = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async getAffiliateByID (affiliateId) {
      return await axios
        .get(`${BeaconapiUrl}/api/affiliate/${affiliateId}`)
        .then(result => {
          this.affiliate = result.data
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async updateAffiliate (affiliate) {
      affiliate.lastModifiedByID = localStorage.getItem('employeeID')
      await axios
        .put(`${BeaconapiUrl}/api/affiliate`, affiliate)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async addNewAffiliate (affiliate) {
      await instance
        .post(`${BeaconapiUrl}/api/affiliate`, affiliate)
        .then(result => {
          this.statusCode = result.status
          if (result.data.status === 409) {
            this.setDangerMessage(result.data.message)
          } else {
            this.setInfoMessage(result.data)
          }
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    },
    async activateAffiliate (affiliates) {
      await axios
        .put(`${BeaconapiUrl}/api/affiliate/activate`, affiliates)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch(error => {
          this.appendDangerMessage(error)
        })
    },
    async removeCompanyAffiliate (companyID, affiliate) {
      await axios
        .put(`${BeaconapiUrl}/api/affiliate/company/remove/${companyID}/${affiliate}`)
        .then(result => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch(error => {
          this.appendDangerMessage(error)
        })
    },
    async getAllCertifiedConsultants () {
      await axios
        .get(`${BeaconapiUrl}/api/affiliate?affiliateType=2`)
        .then((result) => {
          this.statusCode = result.status
          this.consultants = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getAffiliatesByTypes (affiliateTypes, companyID) {
      if (affiliateTypes.length > 0) {
        var affiliateTypeIDs = affiliateTypes.map((a) => `affiliateTypeIDs=${a.affiliateTypeID}`).join('&')
        await axios
          .get(`${BeaconapiUrl}/api/affiliate/company/${companyID}/by-types?${affiliateTypeIDs}`)
          .then((result) => {
            this.affiliates = result.data
          })
          .catch((error) => {
            this.setDangerMessage(error)
          })
      }
    },
    async addCompanyAffiliates (affiliates, companyID) {
      var affiliateIDs = affiliates.map(a => a.affiliateID)
      await axios
        .post(`${BeaconapiUrl}/api/affiliate/company/${companyID}`, affiliateIDs)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getAffiliatePhones (affiliateID) {
      await axios
        .get(`${BeaconapiUrl}/api/affiliate/${affiliateID}/phone`)
        .then((result) => {
          this.statusCode = result.status
          this.affiliatePhones = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getCompanyAffiliate (affiliateID) {
      await axios
        .get(`${BeaconapiUrl}/api/affiliate/company/${affiliateID}/view`)
        .then((result) => {
          this.affiliate = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getAffiliatePhoneByID (phoneID) {
      await axios
        .get(`${BeaconapiUrl}/api/affiliate/phone/${phoneID}`)
        .then((result) => {
          this.statusCode = result.status
          this.affiliatePhone = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async addAffiliatePhone (affiliatePhone) {
      affiliatePhone.phoneNumber = this.removePhoneMask(affiliatePhone.phoneNumber)
      await instance
        .post(`${BeaconapiUrl}/api/affiliate/${affiliatePhone.affiliateID}/phone`, affiliatePhone)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async updateAffiliatePhone (affiliatePhone) {
      affiliatePhone.lastModifiedByID = localStorage.getItem('employeeID')
      affiliatePhone.phoneNumber = this.removePhoneMask(affiliatePhone.phoneNumber)
      await axios
        .put(`${BeaconapiUrl}/api/affiliate/${affiliatePhone.affiliateID}/phone`, affiliatePhone)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async deleteAffiliatePhone (affiliatePhoneID) {
      await axios
        .delete(`${BeaconapiUrl}/api/affiliate/${affiliatePhoneID}/phone`)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getAffiliateContactByContactID (contactID) {
      await axios
        .get(`${BeaconapiUrl}/api/affiliate/contact/${contactID}`)
        .then((result) => {
          this.statusCode = result.status
          this.affiliateContact = result.data
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async addAffiliateContact (affiliateContact) {
      await instance
        .post(`${BeaconapiUrl}/api/affiliate/${affiliateContact.affiliateID}/contact`, affiliateContact)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async updateAffiliateContact (affiliateContact) {
      affiliateContact.lastModifiedByID = localStorage.getItem('employeeID')
      await axios
        .put(`${BeaconapiUrl}/api/affiliate/${affiliateContact.affiliateID}/contact`, affiliateContact)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async deleteAffiliateContact (affiliateContactID) {
      await axios
        .delete(`${BeaconapiUrl}/api/affiliate/${affiliateContactID}/contact`)
        .then((result) => {
          this.statusCode = result.status
          this.setInfoMessage(result.data)
        })
        .catch((error) => {
          this.setDangerMessage(error)
        })
    },
    async getAffiliateByName (name) {
      return await axios
        .get(`${BeaconapiUrl}/api/affiliate/by-name/${name}`)
        .then(result => {
          return !!result.data.length
        })
        .catch(error => {
          this.setDangerMessage(error)
        })
    }
  }
}
</script>
