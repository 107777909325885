<template>
  <b-row>
    <b-col>
      <confirmation ref="Confirmation"></confirmation>
      <b-card>
        <b-row style="margin-bottom:15px;">
          <b-col sm="12" md="6">
            <h4>{{ title }}</h4>
          </b-col>
          <b-col sm="12" md="6">
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="separator mb-sm-2"></div>
          </b-col>
        </b-row>
        <div v-for="phone in contactPhone" :key="phone.companyContactPhoneID"
          style="margin-bottom:10px;">
          <!--ReadOnly-->
          <b-row v-if="editIndex != phone.companyContactPhoneID" align-v="start">
            <b-col lg=3 class="form-group form-inline">
              {{ phone.phoneNumber | VMask(phoneMask)}}
            </b-col>
            <b-col lg=3 class="form-group form-inline">
              <div v-show="phone.phoneNumberExtension">
                Ext.&nbsp;&nbsp;{{phone.phoneNumberExtension}}
              </div>
            </b-col>
            <b-col lg=3 class="form-group form-inline">
              &nbsp;&nbsp;{{loadPhoneDescription(phone.phoneNumberType)}}
            </b-col>
            <b-col lg=3>
              <div class="table-action-buttons-container">
                <b-button @click="startEditing(phone.companyContactPhoneID)"
                  size="sm"
                  variant="primary">
                  Edit
                </b-button>

                <b-button @click="deleteContactPhone(phone.companyContactPhoneID)"
                  size="sm"
                  variant="danger">
                  Delete
                </b-button>
              </div>
            </b-col>
          </b-row>
          <!--Edit -->
          <b-row v-if="editIndex==phone.companyContactPhoneID" align-v="start">
            <b-col lg=3>
              <input type="tel"
                :id="'company-phone-'+phone.companyContactPhoneID"
                @keydown.esc="cancelEditing"
                class="form-control" :class="{ 'is-invalid' : $v.editPhoneRecord.$error }"
                v-model="editPhoneRecord.phoneNumber"
                @input.self="$v.editPhoneRecord.$touch"
                v-mask='phoneMask'/>
              <div class="text-danger" v-if="!$v.editPhoneRecord.phoneNumber.required">Field is required</div>
              <div class="text-danger" v-if="!$v.editPhoneRecord.phoneNumber.minLength">Must have at least 10 digits.</div>
            </b-col>
            <b-col lg=3 class="form-group form-inline">
                <label :for="'company-ext-'+phone.companyContactPhoneID" class="col-sm-3 col-form-label">Ext.</label>
                <input type="text"
                  :id="'company-ext-'+phone.companyContactPhoneID"
                  class="form-control col-sm-9"
                  :class="{ 'is-invalid' : $v.editPhoneRecord.phoneNumberExtension.$error}"
                  @keydown.esc="cancelEditing"
                  v-model="editPhoneRecord.phoneNumberExtension"
                  v-mask="extMask"/>
                <div class="text-danger" v-if="!$v.editPhoneRecord.phoneNumberExtension.maxLength">Must have at most {{$v.editPhoneRecord.phoneNumberExtension.$params.maxLength.max}} digits.</div>
            </b-col>
            <b-col lg=3>
              <b-form-select v-model="editPhoneRecord.phoneNumberType" value-field="phoneNumberTypeID" text-field="phoneNumberTypeDesc" :options="phoneTypes" :class="{ 'is-invalid' : !$v.editPhoneRecord.phoneNumberType.required}" plain></b-form-select>
              <div class="text-danger" v-if="!$v.editPhoneRecord.phoneNumberType.required">Field is required</div>
            </b-col>
            <b-col lg=3>
              <div class="table-action-buttons-container"
                style="margin-top:10px;">

                <b-button @click="cancelEditing()"
                  size="sm"
                  variant="outline-danger">
                  Cancel
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <!--Add -->
        <div v-show="addMode">
          <b-row class="mt-3 pt-3 border-top" align-v="start" v-for="(record, index) in contactPhonesList"
                  :key="index">
            <b-col lg=3>
              <input type="tel"
                id="add-company-phone"
                @keydown.esc="cancelEditing"
                class="form-control" :class="{ 'is-invalid' : $v.contactPhonesList.$each[index].phoneNumber.$error}"
                v-model.number="contactPhonesList[index].phoneNumber"
                @input="$v.contactPhonesList.$each[index].$touch"
                v-mask='phoneMask'
              />
              <div class="text-danger" v-if="!$v.contactPhonesList.$each[index].phoneNumber.required">Field is required</div>
              <div class="text-danger" v-if="!$v.contactPhonesList.$each[index].phoneNumber.minLength">Must have at least {{$v.contactPhonesList.$each[index].phoneNumber.$params.minLength.min}} digits.</div>
            </b-col>
            <b-col lg=3 class="form-group form-inline">
              <label for="add-company-ext" class="col-sm-3 col-form-label">Ext.</label>
              <input type="text"
                id="add-company-ext"
                class="form-control col-sm-9" :class="{ 'is-invalid' : $v.contactPhonesList.$each[index].phoneNumberExtension.$error}"
                @keydown.esc="cancelEditing"
                v-model="contactPhonesList[index].phoneNumberExtension"
                v-mask="extMask"
              />
              <div class="text-danger" v-if="!$v.contactPhonesList.$each[index].phoneNumberExtension.maxLength">Must have at most {{$v.contactPhonesList.$each[index].phoneNumberExtension.$params.maxLength.max}} digits.</div>
            </b-col>
            <b-col lg=3>
              <b-form-select v-model="contactPhonesList[index].phoneNumberType" value-field="phoneNumberTypeID" text-field="phoneNumberTypeDesc" :options="phoneTypes" :class="{ 'is-invalid' : !$v.contactPhonesList.$each[index].phoneNumberType.required}" plain></b-form-select>
              <div class="text-danger" v-if="!$v.contactPhonesList.$each[index].phoneNumberType.required">Field is required</div>
            </b-col>
            <b-col lg=3>
              <div class="table-action-buttons-container">

                <b-button @click="cancelEditing(index)"
                  size="sm"
                  variant="danger">
                  Delete
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-row class="mt-3">
          <b-col>
            <b-button
              pill
              variant="primary"
              class="top-right-button mr-2 float-left"
              @click="addNewPhoneInfo()"
              >Add More Phone Number</b-button
            >
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import confirmation from '../../../components/Common/Confirmation.vue'
import CompanyContact from '../../../mixins/CompanyContactMixin.vue'
import { validationMixin } from 'vuelidate'
import PhoneMixin from '../../../mixins/PhoneMixin.vue'

const {
  required,
  requiredIf,
  maxLength,
  minLength
} = require('vuelidate/lib/validators')
export default {
  name: 'companyContactPhone',
  components: {
    Confirmation: confirmation
  },
  props: ['contactID'],
  mixins: [CompanyContact, PhoneMixin, validationMixin],
  validations: {
    contactPhonesList: {
      required,
      $each: {
        phoneNumber: {
          required: requiredIf(function () {
            return this.addMode === true
          }),
          minLength: minLength(14)
        },
        phoneNumberType: {
          required: requiredIf(function () {
            return this.addMode === true
          })
        },
        phoneNumberExtension: {
          maxLength: maxLength(6)
        }
      }
    },
    editPhoneRecord: {
      phoneNumber: {
        required: requiredIf(function () {
          return this.addMode === false
        }),
        minLength: minLength(14)
      },
      phoneNumberType: {
        required: requiredIf(function () {
          return this.addMode === false
        })
      },
      phoneNumberExtension: {
        maxLength: maxLength(6)
      }
    }
  },
  data () {
    return {
      title: 'Phone Info',
      contactPhoneID: 0,
      contactPhonesList: [{
        phoneNumber: '',
        phoneNumberExtension: '',
        phoneNumberType: ''
      }],
      addMode: true,
      editIndex: -1,
      editPhoneRecord: {
        phoneNumber: '',
        phoneNumberExtension: '',
        phoneNumberType: ''
      }
    }
  },
  async created () {
    await this.refreshPhoneData()
    if (this.contactPhone && this.contactPhone.length > 0) {
      this.addMode = false
      this.contactPhonesList = []
    }
  },
  methods: {
    cancelEditing (index) {
      if (index >= 0) {
        this.contactPhonesList.splice(index, 1)
      } else {
        this.resetPhoneData()
      }
    },
    resetPhoneData () {
      this.contactPhoneID = 0
      this.addMode = false
      this.editIndex = -1
      this.contactPhonesList = []
      this.editPhoneRecord = {
        phoneNumber: '',
        phoneNumberExtension: '',
        phoneNumberType: ''
      }
    },
    startEditing (contactPhoneID) {
      this.contactPhoneID = contactPhoneID
      this.editPhoneRecord = _.find(this.contactPhone, [
        'companyContactPhoneID',
        contactPhoneID
      ])
      this.addMode = false
      this.editIndex = contactPhoneID
    },
    addNewPhoneInfo () {
      this.contactPhonesList.push({
        phoneNumber: '',
        phoneNumberExtension: '',
        phoneNumberType: ''
      })
      this.addMode = true
      this.contactPhoneID = 0
    },
    async savePhone () {
      if (this.addMode) {
        if (this.contactPhonesList.length > 0) {
          this.$v.$touch()
          if (this.$v.contactPhonesList.$error) {
            this.setModalDangerMessage(
              'Invalid entry. Make sure the form has been filled out correctly.'
            )
            return false
          } else {
            await this.addContactPhone()
            return true
          }
        } else { return true }
      } else {
        if (this.editIndex > 0) {
          this.$v.$touch()
          if (this.$v.editPhoneRecord.$error) {
            this.setModalDangerMessage(
              'Edit : Invalid entry. Make sure the form has been filled out correctly.'
            )
            return false
          } else {
            await this.updateContactPhone()
            return true
          }
        } else { return true }
      }
    },
    async updateContactPhone () {
      if (this.editPhoneRecord.phoneNumber) {
        if (this.editPhoneRecord.phoneNumberExtension === '') {
          this.editPhoneRecord.phoneNumberExtension = null
        }
        await this.updateCompanyContactPhone(this.editPhoneRecord)
          .then(() => {
            this.resetPhoneData()
            this.refreshPhoneData()
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    async addContactPhone () {
      this.contactPhonesList.forEach(async record => {
        if (record.phoneNumber) {
          if (record.phoneNumberExtension === '') {
            record.phoneNumberExtension = null
          }
          await this.addCompanyContactPhone({ ...record, contactID: this.contactID })
            .then(() => {
              this.resetPhoneData()
              this.refreshPhoneData()
            })
            .catch((error) => {
              console.log(error)
            })
        }
      })
    },
    async deleteContactPhone (companyPhoneID) {
      this.$refs.Confirmation.show({
        title: 'Delete Phone Number',
        message: 'Are you sure you want to delete this phone number?',
        okButton: 'Delete'
      }).then(async (result) => {
        if (result) {
          await this.deleteCompanyContactPhone(companyPhoneID)
            .then(() => {
              this.resetPhoneData()
              this.refreshPhoneData()
            })
        }
      })
    },
    async refreshPhoneData () {
      if (this.contactID) {
        await this.getCompanyContactPhoneByContactId(this.contactID)
      }
      await this.getPhoneTypes()
    },
    loadPhoneDescription (phoneTypeID) {
      return this.phoneTypes.find(pt => pt.phoneNumberTypeID === phoneTypeID).phoneNumberTypeDesc
    }
  }
}
</script>

<style>
  .form-control.is-invalid {
    border-color: #dc3545 !important;
  }
</style>
