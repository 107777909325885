<template>
  <b-modal id="modalbasic" size="lg" ref="modalbasic" @hide="close()" v-model="isVisible" :title="this.title"
    :no-close-on-backdrop="true" @ok='close()' @cancel='close()'>
    <messages />
    <b-row>
      <b-col>
        <b-card title="Contact Info" icon>
          <b-form>
            <b-row>
              <b-col>
                <b-form-group label="Salutation:" label-for="nested-salutation">
                  <b-form-input
                    id="nested-salutation"
                    class="col-sm-10"
                    v-model="companyContact.salutation"
                    placeholder="Salutation"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="First Name:" label-for="nested-firstName">
                  <b-form-input
                    id="nested-firstName"
                    class="col-sm-10"
                    v-model.trim="$v.companyContact.firstName.$model"
                    :state="!$v.companyContact.firstName.$error"
                    placeholder="First Name"
                  ></b-form-input>
                  <div
                    class="text-danger"
                    :state="!$v.companyContact.firstName.$error"
                    v-if="!$v.companyContact.firstName.required"
                  >
                    First Name is required
                  </div>
                  <!-- <div
                    class="text-danger"
                    :state="!$v.companyContact.firstName.$error"
                    v-else-if="!$v.companyContact.firstName.alpha"
                  >
                    First Name must be composed only with letters!
                  </div> -->
                  <div
                    class="text-danger"
                    :state="!$v.companyContact.firstName.$error"
                    v-else-if="!$v.companyContact.firstName.maxLength"
                  >
                    Maximum Characters Allowed 50
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group label="Last Name:" label-for="nested-lastName">
                  <b-form-input
                    id="nested-lastName"
                    class="col-sm-10"
                    v-model.trim="$v.companyContact.lastName.$model"
                    :state="!$v.companyContact.lastName.$error"
                    placeholder="Last Name"
                  ></b-form-input>
                  <div
                    class="text-danger"
                    :state="!$v.companyContact.lastName.$error"
                    v-if="!$v.companyContact.lastName.required"
                  >
                    Last Name is required
                  </div>
                  <!-- <div
                    class="text-danger"
                    :state="!$v.companyContact.lastName.$error"
                    v-else-if="!$v.companyContact.lastName.alpha"
                  >
                    Last Name must be composed only with letters!
                  </div> -->
                  <div
                    class="text-danger"
                    :state="!$v.companyContact.lastName.$error"
                    v-else-if="!$v.companyContact.lastName.maxLength"
                  >
                    Maximum Characters Allowed 50
                  </div>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="Title:" label-for="nested-title">
                  <b-form-input
                    id="nested-title"
                    class="col-sm-10"
                    v-model.trim="$v.companyContact.title.$model"
                    :state="!$v.companyContact.title.$error"
                    placeholder="Title"
                  ></b-form-input>
                  <div
                    class="text-danger"
                    :state="!$v.companyContact.title.$error"
                    v-if="!$v.companyContact.title.maxLength"
                  >
                    Maximum Characters Allowed 50
                  </div>
                </b-form-group>
              </b-col>
            </b-row>

            <!--Email-->
            <b-row>
              <b-col>
                <b-form-group label="Email:" label-for="nested-emailaddress">
                  <b-form-input
                    id="nested-emailaddress"
                    class="col-sm-6"
                    v-model.trim="$v.companyContact.emailAddress.$model"
                    :state="!$v.companyContact.emailAddress.$error"
                    placeholder="Email"
                  ></b-form-input>
                  <div
                    class="text-danger"
                    :state="!$v.companyContact.emailAddress.$error"
                    v-if="!$v.companyContact.emailAddress.required"
                  >
                    Email is required
                  </div>
                  <div
                    class="text-danger"
                    :state="!$v.companyContact.emailAddress.$error"
                    v-else-if="!$v.companyContact.emailAddress.emailValidation"
                  >
                    Enter a valid email address
                  </div>
                  <div
                    class="text-danger"
                    :state="!$v.companyContact.emailAddress.$error"
                    v-else-if="!$v.companyContact.emailAddress.maxLength"
                  >
                    Maximum Characters Allowed 50
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!--End of Email-->
            <!--Contact Types-->
            <b-row>
              <b-col lg="6">
                <b-form-group label="Contact Type(s):" label-for="nested-contacttypes">
                  <multiselect
                    v-model="companyContact.contactTypes"
                    :options="contactTypeOptions"
                    group-values="options"
                    group-label="label"
                    track-by="id"
                    label="description"
                    :multiple="true"
                    :close-on-select="false"
                    placeholder="Select Contact Type(s)..."
                    style="width: 83.5%"
                    :class="{ 'multiselect-is-invalid' : $v.companyContact.contactTypes.$error }"
                  >
                    <template slot="option" slot-scope="props">
                      <div :title="props.option.tooltip">
                        {{ getOptionLabel(props) }}
                      </div>
                    </template>
                  </multiselect>
                  <div
                    class="text-danger"
                    :state="!$v.companyContact.contactTypes.$error"
                    v-if="!$v.companyContact.contactTypes.required"
                  >
                    Contact type is required
                  </div>
                </b-form-group>
              </b-col >
              <b-col lg="6" v-show="showOtherConsultingFirm">
                    <b-form-group label="Other Consulting Firm" label-for="nested-contacttypes">
                      <b-form-input
                        type="text"
                        v-model="companyContact.otherConsultingFirm"
                        :state="$v.companyContact.otherConsultingFirm.$dirty
                            ? !$v.companyContact.otherConsultingFirm.$error
                            : null
                          "
                        @input="$v.companyContact.otherConsultingFirm.$touch"
                        placeholder="Other Consulting Firm"
                        class="col-sm-10"
                      />
                      <div
                        class="text-danger"
                        v-if="!$v.companyContact.otherConsultingFirm.required &&
                          $v.companyContact.otherConsultingFirm.$dirty
                          "
                      >
                        Other Consulting Firm is required!
                      </div>
                      <div
                        class="text-danger"
                        :state="!$v.companyContact.otherConsultingFirm.$error &&
                          $v.companyContact.otherConsultingFirm.dirty
                          "
                        v-else-if="!$v.companyContact.otherConsultingFirm.maxLength"
                      >
                        Maximum Characters Allowed 300!
                      </div>
                    </b-form-group>
                  </b-col>
            </b-row>
            <!--End of Contact Types-->
            <!-- Optional affiliate info -->
            <b-row v-show="companyContact.contactTypes.find(ct => ct.id === 15) !== undefined">
              <b-col>
                <b-form-group label="Consultant">
                  <b-form-select
                    plain
                    v-model="companyContact.affiliateID"
                    :state="!$v.companyContact.affiliateID.$error"
                    :options="consultants"
                    @input="$v.companyContact.affiliateID.$touch"
                  ></b-form-select>
                  <div
                    class="text-danger"
                    :state="!$v.companyContact.affiliateID.$error"
                    v-if="!$v.companyContact.affiliateID.required"
                  >
                    Consultant is required
                  </div>
                </b-form-group>

                <b-form-group label="Other" v-show="companyContact.affiliateID === -1">
                  <b-form-input
                    type="text"
                    v-model="companyContact.consultantOther"
                    @input="$v.companyContact.consultantOther.$touch"
                    :state="!$v.companyContact.consultantOther.$error"
                  />
                  <div
                    class="text-danger"
                    :state="!$v.companyContact.consultantOther.$error"
                    v-if="!$v.companyContact.consultantOther.required"
                  >
                    Other Consultant is required
                  </div>
                  <div
                    class="text-danger"
                    :state="!$v.companyContact.consultantOther.$error"
                    v-else-if="!$v.companyContact.consultantOther.minLength"
                  >
                    Minimum Characters Allowed 2
                  </div>
                  <div
                    class="text-danger"
                    :state="!$v.companyContact.consultantOther.$error"
                    v-else-if="!$v.companyContact.consultantOther.maxLength"
                  >
                    Maximum Characters Allowed 50
                  </div>
                </b-form-group>
              </b-col>
            </b-row>
            <!--End optional affiliate info-->
            <!--Notes-->
            <b-row>
              <b-col>
                <b-form-group label="Notes:" label-for="nested-notes">
                  <b-form-textarea
                    id="nested-notes"
                    class="col-sm-8"
                    rows="3"
                    v-model.trim="companyContact.notes"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>
            <!--End of Notes-->
          </b-form>
        </b-card>
      </b-col>
    </b-row>

    <!--Contact Phone-->
    <div>
      <CompanyContactPhone :contactID="this.contactID" ref="CompanyContactPhone"></CompanyContactPhone>
    </div>
    <!--End Contact Phone-->
    <template #modal-footer="{ ok }">
      <b-button
        size="lg"
        variant="outline-danger"
        class="mr-1"
        @click="ok">
        Cancel
      </b-button>
      <b-button v-if="!$v.$invalid"
        size="lg"
        variant="success"
        class="mr-1"
        @click="saveCompanyContact()">
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<style lang="scss" scoped>
.is-invalid {
  border: 1px solid #dc3545 !important;
}

.multiselect-is-invalid ::v-deep .multiselect__tags {
  border: 1px solid #dc3545 !important;
}
</style>

<script>

import CompanyContactPhone from '../../views/app/company/CompanyContactPhone.vue'
import CompanyContactMixin from '../../mixins/CompanyContactMixin.vue'
import { validationMixin } from 'vuelidate'
import PhoneMixin from '../../mixins/PhoneMixin.vue'
import AffiliateMixin from '../../mixins/AffiliateMixin.vue'
import Multiselect from 'vue-multiselect'
import { mapMutations } from 'vuex'
import { helpers } from 'vuelidate/lib/validators'
import ModalMessages from '../../containers/navs/ModalMessages.vue'

const {
  required,
  requiredIf,
  // alpha,
  maxLength,
  minLength,
  email
} = require('vuelidate/lib/validators')
const emailValidation = helpers.regex('emailValidation', /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)
export default {
  name: 'contactModal',
  components: {
    CompanyContactPhone: CompanyContactPhone,
    Multiselect,
    messages: ModalMessages
  },

  mixins: [CompanyContactMixin, PhoneMixin, validationMixin, AffiliateMixin],
  validations: {
    companyContact: {
      firstName: {
        required,
        // alpha,
        maxLength: maxLength(50)
      },
      lastName: {
        required,
        // alpha,
        maxLength: maxLength(50)
      },
      emailAddress: {
        required,
        emailValidation,
        maxLength: maxLength(50)
      },
      title: {
        maxLength: maxLength(50)
      },
      contactTypes: {
        required
      },
      affiliateID: {
        required: requiredIf(function () {
          return this.companyContact.contactTypes.find(ct => ct.id === 15) !== undefined
        })
      },
      consultantOther: {
        required: requiredIf(function () {
          if (this.companyContact.affiliateID === null) {
            return false
          }

          return this.companyContact.affiliateID === -1
        }),
        minLength: minLength(2),
        maxLength: maxLength(300)
      },
      otherConsultingFirm: {
        required: requiredIf(function () {
          return this.showOtherConsultingFirm
        }),
        maxLength: maxLength(300)
      }
    }
  },
  data: () => ({
    title: undefined,
    isVisible: false,
    contactID: 0
  }),

  async created () {
    await this.getContactTypeOptions()
    await this.getAllCertifiedConsultants()
    this.mapConsultants()
  },
  computed: {
    showOtherConsultingFirm () {
      return (
        this.companyContact.contactTypes &&
        this.companyContact.contactTypes.length &&
        this.companyContact.contactTypes.find(({ id }) => id === 4)
      )
    }
  },

  methods: {
    ...mapMutations([
      'setModalDangerMessage',
      'setModalInfoMessage'
    ]),
    async show (opts = {}) {
      this.title = opts.title

      if (opts.exitFunction) {
        this.exitFunction = opts.exitFunction
      }

      if (opts.contactID) {
        this.contactID = (opts.contactID)
        await this.getCompanyContactByContactID(this.contactID)
      }

      if (opts.companyID) {
        this.initNewContact(opts.companyID)
      }
      this.isVisible = true
    },

    close () {
      if (this.exitFunction) {
        this.exitFunction()
      }

      this.contactID = 0
      this.setModalDangerMessage('')
      this.setModalInfoMessage('')
      this.isVisible = false
    },
    async saveCompanyContact () {
      const contactDetails = {
        ...this.companyContact,
        otherConsultingFirm: this.showOtherConsultingFirm
          ? this.companyContact.otherConsultingFirm
          : null
      }
      if (this.contactID) {
        const flag = await this.$refs.CompanyContactPhone.savePhone()
        if (flag) {
          await this.updateCompanyContact(contactDetails)
          this.close()
        }
      } else {
        var id = await this.addCompanyContact(contactDetails)
        if (this.statusCode === 200) {
          // If the contact was successfully added, user stays on form, but future saves are treated as updates now
          this.contactID = id
          await this.getCompanyContactByContactID(this.contactID)
          const flag = await this.$refs.CompanyContactPhone.savePhone()
          if (flag) { this.close() }
        }
      }
    },
    initNewContact (companyID) {
      this.companyContact = {
        companyID: companyID,
        firstName: '',
        lastName: '',
        salutation: '',
        title: '',
        emailAddress: '',
        notes: null,
        createdByID: 0,
        active: true,
        phoneNumbers: '',
        affiliateID: null,
        consultantOther: null,
        contactTypes: [],
        otherConsultingFirm: null
      }
    },
    mapConsultants () {
      this.consultants = this.consultants.map((c) => {
        return {
          text: c.companyName,
          value: c.affiliateID
        }
      })

      // Pierson 9/15/21 - I added the push in here instead of in the API
      // so that it would keep the API agnostic and not mess up other GetAll calls
      this.consultants.push({
        value: -1,
        text: 'Other'
      })
    },
    getOptionLabel (props) {
      if (props.option.description === undefined) {
        return props.option.$groupLabel
      }

      return props.option.description
    }
  }
}
</script>
